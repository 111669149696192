import './App.css'
import React, { useState, useRef, useEffect } from 'react'
import ShaderCanvas from './ShaderCanvas'

// const API_URL = "http://localhost:7001"
// const API_URL = "http://192.168.1.228:7001"
const API_URL = "https://api.halbucd.com"

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
  set: (searchParams, prop, value) => {
    searchParams.set(prop, value)

    const newUrl =
      window.location.href.replace(window.location.search, '')
      + "?"
      + searchParams

    window.history.replaceState(null, null, newUrl)

    return true
  }
});

const parseFragmentShaderForRemixing = (fragmentShader) => {
  fragmentShader = fragmentShader.replace("precision highp float;", "")

  const pattern = /(\d*\.\d+)/g

  let uniforms = []
  let i = 0
  let skips = 30

  var result = fragmentShader.replace(pattern, (match, group) => {
    if (skips > 0) {
      skips -= 1
      return match
    }

    if (i < 20) {
      const name = 'u_var' + i++
      uniforms.push([name, { defaultValue: parseFloat(match) + 0 }])
      return name
    }

    return match
  })

  result = uniforms.reduce(
    (result, currVar) => `uniform float ${currVar[0]};` + result,
    result
  )

  return { shader: 'precision highp float;' + result, uniforms: Object.fromEntries(uniforms) }
}

const Slider = ({ value, onMouseUp, min, max, label, step, onChangeValue, onMouseDown, triggerOnSlide }) => {
  const [internalValue, setInternalValue] = useState(value)
  const [internalMin, setInternalMin] = useState(min)
  const [internalMax, setInternalMax] = useState(max)
  const [internalStep, setInternalStep] = useState(step)

  const updateMinMaxStep = () => {
    let newInternalMin = min || internalValue - (Math.abs(internalValue) + 2) * 1
    let newInternalMax = max || internalValue + (Math.abs(internalValue) + 2) * 1

    if (min === undefined) {
      setInternalMin(newInternalMin)
    }

    if (max === undefined) {
      setInternalMax(newInternalMax)
    }

    if (step === undefined) {
      setInternalStep(Math.abs(newInternalMin - newInternalMax) / 100)
    }
  }

  useEffect(() => {
    updateMinMaxStep()
  }, [min, max, step])

  useEffect(() => {
    if (value != internalValue) {
      setInternalValue(value)
    }
  }, [value])

  return (
    <label className='slider-label'>
      {label}
      <div>
        <button onClick={(e) => {
          const newValue = parseFloat(internalValue) - parseFloat(internalStep)
          setInternalValue(newValue)
          onChangeValue(newValue)
        }}>-</button>
        <input
          type='range'
          min={internalMin}
          max={internalMax}
          step={internalStep}
          value={internalValue}
          onPointerUp={e => {
            updateMinMaxStep()
            onMouseUp(e)
            onChangeValue(e.target.value)
          }}
          onChange={e => {
            setInternalValue(parseFloat(e.target.value))

            if (triggerOnSlide) {
              onChangeValue(e.target.value)
            }
          }}
          onPointerDown={e => {
            onMouseDown(e)
          }}
        />
        <button onClick={(e) => {
          const newValue = parseFloat(internalValue) + parseFloat(internalStep)
          setInternalValue(newValue)
          onChangeValue(newValue)
        }}>+</button>
      </div>
    </label>
  )
}

// const CHUB_CLUB_SEED_PARAMS = {
//   geometrySeed: { label: 'Geometry Seed', step: 1, min: 1, max: 10, default: 1 },
//   geometryComplexity: { label: 'Geometric Complexity', step: 1, min: 5, max: 100, default: 5 },
//   textureSeed: { label: 'Texture Seed', step: 1, min: 1, max: 10, default: 1 },
//   textureComplexity: { label: 'Textural Complexity', step: 1, min: 1, max: 10, default: 1 },
// }

// // description for how to handle predefined uniforms
// const CHUB_CLUB_REMIX_PARAMS = {
//   u_uv_offset_x: { label: 'UV Offset X', step: 0.1, min: -3, max: 3, default: 0 },
//   u_uv_offset_y: { label: 'UV Offset Y', step: 0.1, min: -3, max: 3, default: 0 },
//   u_zoom: { label: 'Zoom', step: 0.1, min: 0.1, max: 10, default: 1 },
//   u_distance: { label: 'Distance', step: 0.1, min: -5, max: 10, default: 2.5 },
//   u_x_pan: { label: 'X Pan', step: 0.1, min: -Math.PI, max: Math.PI, default: 0 },
//   u_y_pan: { label: 'Y Pan', step: 0.1, min: -Math.PI, max: Math.PI, default: 0 },
// }

function getDefaultParams(PARAMS, useUrlParams = true) {
  const urlParams = new URLSearchParams(window.location.search)
  return Object.fromEntries(Object.entries(PARAMS).map(([key, params]) => {
    const value = (useUrlParams && urlParams.has(key)) ? parseFloat(urlParams.get(key)) : params.defaultValue
    return ([key, value])
  }))
}

function App() {
  const urlParams = new URLSearchParams(window.location.search)
  const [fragmentShader, setFragmentShader] = useState("")
  const pointerIsDownRef = useRef(false)
  // const [seedParams, setSeedParams] = useState(getDefaultParams(CHUB_CLUB_SEED_PARAMS))
  // const [predefinedUniformValues, setRemixParams] = useState(getDefaultParams(CHUB_CLUB_REMIX_PARAMS))
  const [seedParams, setSeedParams] = useState(null)
  const [predefinedUniformValues, setRemixParams] = useState(null)
  const [generatedUniformValues, setRemixUniforms] = useState(null)
  const [pausePreview, setPausePreview] = useState(false)
  const [algoList, setAlgoList] = useState({})
  const [currentAlgo, setCurrentAlgo] = useState(params.currentAlgo)

  useEffect(() => {
    if (predefinedUniformValues && seedParams && generatedUniformValues) {
      for (const [key, value] of Object.entries(predefinedUniformValues)) {
        urlParams.set(key, value)
      }

      for (const [key, value] of Object.entries(generatedUniformValues)) {
        urlParams.set(key, value)
      }

      for (const [key, value] of Object.entries(seedParams)) {
        urlParams.set(key, value)
      }

      const newUrl =
        window.location.href.replace(window.location.search, '')
        + "?"
        + urlParams

      window.history.replaceState(null, null, newUrl)
    }
  }, [predefinedUniformValues, seedParams, generatedUniformValues])


  const fetchShader = () => {
    console.log("fetching new shader", JSON.stringify(seedParams))
    fetch(`${API_URL}/algos/${currentAlgo}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(seedParams)
    })
      .then(response => {
        response.text().then(fragmentShader => {
          const { shader, uniforms } = parseFragmentShaderForRemixing(fragmentShader)

          // console.log(uniforms)
          // console.log(getDefaultParams(uniforms))
          setRemixUniforms(getDefaultParams(uniforms))

          setFragmentShader(shader)
        })
      })
  }

  const fetchAlgoList = () => {
    console.log("fetching algo list")
    fetch(`${API_URL}/algos`)
      .then(response => {
        response.json().then(algoList => {
          console.log("received algo list", algoList)
          setAlgoList(algoList)

          if (!currentAlgo) {
            setCurrentAlgo(Object.keys(algoList)[0])
          }
        })
      })
  }

  useEffect(() => {
    if (currentAlgo && Object.keys(algoList).length > 0) {
      console.log("new algo selected, resetting params", currentAlgo)
      // setSeedParams(getDefaultParams(algoList[currentAlgo].seed))
      // setRemixParams(getDefaultParams(algoList[currentAlgo].uniforms))
      setSeedParams(getDefaultParams(algoList[currentAlgo].seed), false)
      setRemixParams(getDefaultParams(algoList[currentAlgo].uniforms), false)
      // setRemixUniforms({})
      params.currentAlgo = currentAlgo
    }
  }, [currentAlgo, algoList])

  useEffect(() => {
    fetchAlgoList()
  }, [])

  useEffect(() => {
    if (currentAlgo && seedParams) {
      fetchShader()
    }
  }, [seedParams])

  const uniforms = {
    ...predefinedUniformValues,
    ...generatedUniformValues
  }

  return (
    <div id='container'>
      <ShaderCanvas
        paused={pausePreview}
        className='background-canvas'
        uniforms={{ ...uniforms, 'u_zoom': uniforms.u_zoom + 10 }}
        width={50}
        height={50}
        fragShader={fragmentShader}
      />
      <div className="background-image"></div>
      <div className="background-overlay"></div>

      {/* <textarea rows={42} style={{ width: "45%", position: 'relative' }} onBlur={e => setFragmentShader(e.target.value)} defaultValue={fragmentShader}></textarea> */}

      <div className="preview-container">
        <ShaderCanvas
          paused={pausePreview}
          // paused={true}
          className='preview-canvas'
          uniforms={uniforms}
          width={100}
          height={100}
          fragShader={fragmentShader}
          onPointerDown={e => {
            pointerIsDownRef.current = true
          }}
          onPointerUp={e => {
            pointerIsDownRef.current = false
          }}
          onPointerMove={e => {
            if (pointerIsDownRef.current) {
              setRemixParams(p => {
                return {
                  ...p,
                  u_uv_offset_x: p.u_uv_offset_x - e.movementX * 0.005,
                  u_uv_offset_y: p.u_uv_offset_y + e.movementY * 0.005,
                }
              })
            }
          }}
          onWheel={(e) => {
            setRemixParams(p => {
              return { ...p, u_zoom: p.u_zoom * (1 + e.deltaY * 0.0002) }
            })
          }}
        />

        <div className="snapshots">
          {[0.25, 2.0, 5.5, 8.5].map(t => {
            return <ShaderCanvas
              paused={true}
              className='snapshot-canvas'
              uniforms={{ ...uniforms, u_time: t }}
              width={100}
              height={100}
              fragShader={fragmentShader}
            />
          })}
        </div>

        <select className="algo-select" onChange={(e) => setCurrentAlgo(e.target.value)} value={currentAlgo}>
          {Object.entries(algoList).map(([key, _]) => <option key={key} value={key}>{key}</option>)}
        </select>

        <div className="sliders-left">

          {(algoList[currentAlgo] && seedParams) && Object.entries(algoList[currentAlgo].seed).map(([key, params]) => {
            return <Slider key={key} {...params} value={seedParams[key]}
              onChangeValue={(value => {
                const newValue = parseFloat(value)

                setSeedParams(p => {
                  return { ...p, [key]: newValue }
                })

                // fetchShader(params)
              })}
              onMouseDown={() => {
                setPausePreview(true)
              }}
              onMouseUp={() => {
                setPausePreview(false)
              }}
            />
          })}

          {(algoList[currentAlgo] && predefinedUniformValues) && Object.entries(algoList[currentAlgo].uniforms).map(([key, params]) => {
            return <Slider key={key} {...params}
              value={predefinedUniformValues[key]}
              onChangeValue={(value => {
                const newValue = parseFloat(value)
                setRemixParams(p => {
                  return { ...p, [key]: newValue }
                })
              })}
              onMouseDown={() => {
                setPausePreview(true)
              }}
              onMouseUp={() => {
                setPausePreview(false)
              }}
              triggerOnSlide
            />
          })}

          {generatedUniformValues && Object.entries(generatedUniformValues).map(([key, value]) => {
            return <Slider key={key} value={value}
              label={key}
              onChangeValue={(value => {
                const newValue = parseFloat(value)
                setRemixUniforms(u => {
                  return { ...u, [key]: newValue }
                })
              })}
              onMouseDown={() => {
                setPausePreview(true)
              }}
              onMouseUp={() => {
                setPausePreview(false)
              }}
              triggerOnSlide
            />
          })}
        </div>
      </div>

      {/* <div className="sliders-right">
        

        
      </div> */}
    </div>
  )
}

export default App;
